import type { ConctactFormsPostTextFields } from "../../translation-keys/contact-post-submit-text-keys.js";

const contactFormText: ConctactFormsPostTextFields = {
  "contact-message": `後ほど担当者よりご連絡を差し上げますのでしばらくお待ちくださいませ。 {phone-number-slot}`,
  "contact-phone-number": "",
  "contact-thank-you": "ご連絡いただきありがとうございます。",
  "contact-href-value": "",
  "qx-roi-message": "",
  "mx-roi-message": "",
  "spark-message": "",
  "pricing-message": "価格についてのお問い合わせを承りましたので、近日中にご回答させていただきます。",
  "pricing-thank-you" : "ありがとうございました。",
  "pricing-rc-statement": "MasterControlについての詳しい情報は、 ",
  "pricing-rc": "リソースセンターをご覧ください。",
  "subscription-message": "ご登録いただきありがとうございます。",
  "partner-message": "お問い合わせいただきありがとうございます。2営業日以内にご回答させていただきます。",
  "demo-request-message": "お問い合わせいただきありがとうございます。デモの日程について、担当者からご連絡いたします。当社の品質ソリューションをご紹介できることを楽しみにしております。",
  "tradeshow-giveaway-message": "ありがとうございました。入力は完了しました。当選者には5営業日以内にご連絡いたします。",
  "event-registration-message": "ご登録ありがとうございました。",
  "subprocessor-update-notification-message": "",
  "ppc-contact-message": "",
  "raise-your-hand": "当社に関心をお寄せいただきありがとうございます。",
  "event-registration-support-copy": "まもなくイベントの詳細を記載した確認メールが届きます。"

};
export default contactFormText;